.contenOrder {
    display: flex;
    flex-direction: column;
    width: 85vw;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
}

.titleOrder {
    display: flex;
    background-color: rgb(70, 110, 10);
    color:lemonchiffon;
    justify-content: center;
    text-shadow: 1px 2px 3px lightseagreen;
}

.titleLines {
   /*  display: flex;
    flex-direction: row; */
    background-color: rgb(70, 110, 10);
    color:lemonchiffon;
    align-content: center;
    justify-content: center;
    text-shadow: 1px 2px 3px lightseagreen;
}

.orderLineProduct {
    display: flex;
    align-content:center;
    justify-content:flex-start;
}

.imageProduct {
    display: flex;
    border-radius: 100%;
    width: 48px;
    height: auto;
}

.importePedido {
    display: flex;
    justify-content: right;
    font-family: Arial, Helvetica, sans-serif;
    font-size: larger;
    font-weight: bolder;
    color: red;
}

@media screen and (max-width: 361px) {

    .imageProduct {
        display: none;
    }
}
