.h1 {
    color: white;
    font-weight: 700;
}

.h3 {
    color: white;
    font-weight: 350;
}

.h5 {
    color: gray;
    font-weight: 350;
}

.h6 {
    color: rgb(97, 97, 97);
    font-weight: 350;
}
