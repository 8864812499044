.contentalias {
    display: flex;
    align-self: center;
    flex-direction: column;
    border: solid;
    border-color: antiquewhite;
}

.contenthora {
    display: flex;
    align-self: center;
    flex-direction: column;
    background-color: aliceblue;
}

.contentatencion {
    display: flex;
    align-self: center;
    flex-direction: column;
    background-color: rgb(252, 241, 229);
    color: red
}

.contentprecio {
    display: flex;
    align-self: center;
    flex-direction: column;
    color: aliceblue;
    font-size: medium;
    padding: 3px;
}

.contentinstall {
    display: block;
    align-self: center;
    flex-direction: column;
    background-color: aliceblue;
    color: black;
    font-size: medium;
}

.contentpdf {
    display: block;
    align-self: center;
    flex-direction: column;
    background-color: lightcoral;
    font-size: medium;
}

.contentnota {
    display: block;
    align-self: center;
    flex-direction: column;
    background-color: red;
    color: white;
    font-size: medium;
}

.divprecio {
    font-size: large;
    font-weight: bolder;
}

.divalias {
    display: flex
}

.importe {
    color: blanchedalmond;
}

.cuentas {
    text-align: start;
    color: blanchedalmond;
}

.boton {
    margin: 3px;
    border-radius: 10px;
    background-color: aquamarine;
}

.formulario {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: antiquewhite;
    color: rgb(4, 2, 107);
    font-size: medium;
}

.showcase {
    background-image: url("../images/iptv.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.contentinstallapp {
    background-color: rgba(200, 250, 247, 0.4);
    backdrop-filter: blur(10px);
    /* backdrop-filter: blur(10px);
        display: block;
    align-self: center;
    flex-direction: column;
    /* background-color: rgb(5, 135, 250); */
/*     color: rgb(255, 174, 0);
    font-size: medium; */
}