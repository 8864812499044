.navbar {
  background-color: rgba(255, 255, 255, 0.789);
  width: 100%;
}

.iconcart {
  width: 30px;
  height: 30px;
}

.navbar-logo {
  color: rgb(0, 0, 0);
  justify-self: start;
  top: 0px;
  /* margin-left: 2px; */
  /* cursor: pointer; */
  text-decoration: none;
  font-size: 1.5rem;
  width: 45px;
  height: auto;
  margin-left: 20px
}

.navbar-text {
  color: green;
  font-size: 2em;
}

.navbar-logo:hover {
  color: #4AA96C;
}

.sham {
  color: #4AA96C
}

.shopBagImg {
  width: 50%;
  height: 50%;
  margin-top: 2em;
  margin-bottom: 2em;
}

.navigation {
  height: 60px;
  width: auto
    /* 100% */
  ;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  opacity: 0.9;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  z-index: 100;
  /* traer al frente */
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  display: flex;
  height: auto;
  margin-left: auto;
}

/* // navbar.css */
.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  /* // removes default disc bullet for li tags and applies margin to left & right side */
  list-style-type: none;
   margin: 0 1rem; 
}

.navigation-menu li a {
  /* // increases the surface area of the anchor tag to span more than just the anchor text */
  text-decoration: none;
  display: block;
  width: 100%;
}

/* // navbar.css */
.hamburger {
  /* // removes default border on button element */
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #100152;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #4163ed;
}

.spancelu {
  display: none;
}

.span {
  display: block;
}

@media screen and (max-width: 875px) {

  .navbar-text {
    display: none;
    font-size: 1em;
  }

  /*   .shopBagImg {
    width: 20px;
    height: 20px;
    margin-top: 2em;
    margin-bottom: 2em;
  } */

  .iconcart:hover {
    color: #000000;
  }

  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu ul {
    /*  // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
    position: absolute;
    top: 60px;
    left: 0;
    /* // stacks the li tags vertically  */
    flex-direction: column;
    /* // makes menu span full height and width */
    width: 100%;
    height: auto
      /* calc(100vh - 77px) */
    ;
    background-color: rgb(22, 0, 100);
    border-top: 1px solid black;
  }

  .navigation-menu li {
    /* // centers link text and strips off margin */
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: rgb(185, 184, 184);
    /*  // increases the surface area of the anchor tag to span the full width of the menu */
    width: 100%;
    padding: 1.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu li a:hover {
    color: #000000;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .spancelu {
    display: none;
  }

  .span {
    display: block;
  }
}

@media screen and (max-width: 430px) {
  .span {
    display: none;
  }

  .spancelu {
    display: block;
    /*     font-size: .75rem; */
  }

  .iconcart {
    width: 25px;
    height: 25px;
  }

  .hamburger {
    width: 35px;
    height: 35px;
    right: 50px;
  }

  .navbar-logo {
    margin-left: 10px;
  }
}

@media screen and (max-width: 375px) {
  .iconcart {
    width: 22px;
    height: 22px;
    right: 1px;
  }

  .hamburger {
    width: 32px;
    height: 32px;
    right: 40px;
  }

  .navbar-logo {
    margin-left: 5px;
  }

}