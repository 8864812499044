.regcontainer {
    position: relative;
    top: 4em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.boxreg {
    width: 500px;
    height: auto;
    border: 1px solid rgb(82, 76, 76);
    border-radius: 1em;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
}

.titreg {
    display: flex;
    top: 0em;
    height: 2em;
    background-color: rgb(11, 160, 33);
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    margin-bottom: 1em;
}

.cuerporeg {
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: white; */
    font-weight: bold;
    margin-bottom: 1em;
}

label {
    display: flex;
    align-items: left;
    justify-content: left;
    /* color: white; */
}