@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Otomanopee+Ones:wght@1,700&display=swap'); */

.textwhite {
    color: rgb(171, 233, 244);
    font-family: 'Open Sans', sans-serif;
}
 
.wc {
    color: rgb(9, 153, 4);
    text-align: center;
    font-size: 20px;
    padding: 20px;
}

.btn {
    padding: 1rem
}

.containerprod {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90% ;
    border-radius: 1em;
    border: 5px rgb(38, 0, 255);
    font-family: 'Open Sans', sans-serif;
    padding: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(189, 189, 187, .8);
}


 .card_container {
    display: flex;
    width: 100%;
    height: auto;
/*     background: rgb(189, 189, 187, .8);
    border-radius: 5px;
    box-shadow: 3px 3px 8px 4px rgba(0, 0, 0, .2); */
}

.header {
    width: 100%;
    border-right: 1px solid #dddddd;
}

.description {
    background: transparent;
    width: 100%;
}

.header,
.description {
    padding: 20px;
    text-align: start;
}

.imagen_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.imagen {
    width: 95% ;
    height: auto ;
    border-radius: 1rem;
}


.imgloader {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}


.centerbut {
    display: flex;
    align-items: center;
    padding: .5em;
    font-size: large;
    border-radius: 1em;
    margin-left: auto;
    margin-right: auto;
    color: beige;
    background: blue;
}


@media screen and (max-width: 730px) {

    .wc {
        color: rgb(9, 153, 4);
        text-align: center;
        padding: 2px;
    }

    .containerprod {
        display: grid;
        /*         align-items: center;
        justify-content: center; */
        border-radius: 1em;
        border: 5px rgb(38, 0, 255);
        /*     height: 100vh;      */
        font-family: 'Open Sans', sans-serif;
        padding: 5px;
        width: 90%;
        background-color: transparent;
    }

    .card_container {
        display: flex;
        width: 99%;
        height: auto;
        background: rgb(189, 189, 187, .8);
        border-radius: 5px;
        box-shadow: 3px 3px 8px 4px rgba(0, 0, 0, .2);
    }

    .description {
        background: transparent;
        width: 100%;
    }

    .imagen {
        width: 90%;
        height: auto;
        align-self: center;
    }
}