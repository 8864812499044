.barraBuscar {
  margin: 50px;
}

.searchbut{
  margin-left:-8px;
  border-radius: 0px 5px 5px 0px;
}

.searchinp{
  border-radius: 5px 0px 0px 5px;
}


@media screen and (max-width: 380px) {
  .barraBuscar {
    margin: 5px;
  }
}
