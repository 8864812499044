.fondo {
  background:white;
  ;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  justify-content: space-around;
  margin-top: 2rem;
  padding: 2rem;
/*   border-top:1px dotted #F55C47;
 */}

 .letra {
   font-size: large;
 }