.menuitems {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content:space-around;
    border:4px solid greenyellow;
}

.boxdash {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.boxorders {
    width: 750px;
    align-items: center;
    justify-content: center;
}

.contentOrders {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    border: 2px dashed greenyellow;
    margin:auto
}

.boxOrder {
    display: flex;
    flex-wrap: wrap;
    width: 370px;
    margin: auto;
    height: 425px;
    overflow: auto;
}

.boxTitleOrder {
    display: flex;
    height: 2em;
    width: 100%;
    background-color: forestgreen;
    color: ghostwhite;
    align-items: center;
    justify-content: center;
}
