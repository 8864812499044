.containerppv {
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
    background-image: url("../../images/livestream.avif");
    background-repeat: repeat-x;
    background-size: cover;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    background-color: antiquewhite;
    border: 1px;
    border-style: dashed;
    border-color: red;
    width: 85%;
    border-radius: 25px;
    height: 500px;

}

.boxaviso {
    /*     display: flex; */
    background-color: rgba(255, 255, 255, 0.75);
    width: 250px;
    height: auto;
    border: 2em;
    border-color: green;
    border-radius: 15px
}

.boxtitle {
    display: flex;
    align-items: center;
    /* alineacion vertical */
    justify-content: center;
    /* alineacion horizontal */
    background-color: #9D16EF;
    padding: "auto";
    height: 40px;
    color: white;
    border-radius: 15px 15px 10px 10px
}

.boxip {
    display: flex;
    align-items: center;
    /* alineacion vertical */
    justify-content: space-around;
    /* alineacion horizontal */
    background-color: #9D16EF;
    padding: "auto";
    height: 40px;
    color: white;
    border-radius: 15px 15px 10px 10px
}

.textoppv {
    padding: 2em;
    font-size: 22px;
    color: darkblue;
    font-weight: bolder;
}

.vacia {
    height: 50%;
    width: auto;
}

.containerlist {
    display: flex;
    flex-wrap: wrap;
    justify-self: center;
    justify-content:space-around;
    align-items:center;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    background-color: antiquewhite;
    border: 1px;
    border-style: dashed;
    border-color: red;
    width: 75%;
    border-radius: 25px;
}

.boxiplist {
     display: flex;
     flex-direction: column;
    align-items: center;
    /* alineacion vertical */
    justify-content: space-around;
    /* alineacion horizontal */
    background-color: #9D16EF;
    padding: "auto";
 /*    height: 40px; */
    color: white;
    border-radius: 15px 15px 10px 10px;
    width: 50%;
    
}

.boxavisolist {
/*     display: flex;  */
    background-color: rgba(255, 255, 255, 0.75);
    width: 45%;
    height: auto;
    border: 2em;
    border-color: green;
    border-radius: 15px
}

.ipview {
    font-size: medium;
}