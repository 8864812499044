.boxteam {
    width: 320px;
    height: auto;
    border: 1px solid rgb(82, 76, 76);
    border-radius: 1em;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
}

.titteam {
    display: flex;
    top: 0em;
    height: 2em;
    background-color: #4AA96C;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    margin-bottom: 1em;
    border-radius:5px;
}

.logsub {
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin: 1em;
    background-color: rgb(241, 238, 255);
}

.cajaform {
    display: flex;
    flex-direction:row;
    width: 280px;
    border: 2px solid black;
    border-radius:  20px;
    background-color:rgba(20, 20, 20, 0.6);
    color: honeydew;
    background-position: center center;
    padding: 1rem;
    margin: 17rem;
}

.danger {
    color: orangered;
    font-weight: bold;
}

.cajaregister {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    border: 2px solid black;
    border-radius:  20px;
    background-color:rgba(138, 138, 138, 0.6);
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
}