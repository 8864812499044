.paper-cart{
    color: #2B3445;
    background-color: white;
}

.avatar{
    margin: 10px;
}

.prod-title{
    font-weight:500;
    font-size:14px;
    margin-bottom: 40px;
}

.sum{
    color:green;
    font-weight:300;
    font-size: 1em;
}

.result{
    color:red;
    font-weight:500;
    font-size: 1em;
}

.totalview {
    display: flex;
    font-size: x-large;
    color: forestgreen;
    font-weight: bolder;
}

.length{
    overflow: hidden;
    max-width: "80%";
    text-overflow: ellipsis;
    white-space: nowrap;   
}